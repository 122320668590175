@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .dark {
    @apply bg-gray-900 text-white;
  }

  .dark body {
    @apply bg-gray-900 text-white;
  }
}
